import React from "react";
import css from "./index.module.scss";
import DefaultLayout from "layouts/default";
import PortraitSvg from "/public/images/icons/portrait.svg";
import PenNibSvg from "/public/images/icons/penNib.svg";
import IconsSvg from "/public/images/icons/icons.svg";
import AdSvg from "/public/images/icons/ad.svg";
import ImageSvg from "/public/images/icons/image.svg";
import PaintBrushSvg from "/public/images/icons/paintBrush.svg";
import SearchSvg from "/public/images/icons/search.svg";
import ParagraphSvg from "/public/images/icons/paragraph.svg";
import NewspaperSvg from "/public/images/icons/newspaper.svg";
import AnalyticsSvg from "/public/images/icons/analytics.svg";
import BookSvg from "/public/images/icons/book.svg";
import CodeSvg from "/public/images/icons/code.svg";

const categories = [
  {
    icon: <ParagraphSvg/>,
    label: "Creative Writing",
    description: "Express yourself in an unconventional manner.",
  },
  {
    icon: <NewspaperSvg/>,
    label: "Content Creation",
    description: "Create compelling articles and social media posts.",
  },
  {
    icon: <SearchSvg/>,
    label: "Research",
    description: "Ask a clever question, get a clever answer.",
  },
  {
    icon: <AnalyticsSvg/>,
    label: "Data Analysis",
    description: "Summarize, sort, and process data.",
  },
  {
    icon: <CodeSvg/>,
    label: "Programming",
    description: "Speed up your development cycle.",
  },
  {
    icon: <BookSvg/>,
    label: "Education",
    description: "Master a new field with an AI teacher.",
  },
  {
    icon: <PaintBrushSvg/>,
    label: "Visual Art",
    description: "Draw stunning artwork for your latest project.",
  },
  {
    icon: <ImageSvg/>,
    label: "Photography",
    description: "Say goodbye to repetitive stock imagery.",
  },
  {
    icon: <PortraitSvg/>,
    label: "Portraits",
    description: "Flesh out your profile with a custom portrait.",
  },
  {
    icon: <PenNibSvg/>,
    label: "Graphic Design",
    description: "Draft stunning logos and mockups.",
  },
  {
    icon: <IconsSvg/>,
    label: "Iconography",
    description: "Create beautiful icons for your apps and sites.",
  },
  {
    icon: <AdSvg/>,
    label: "Advertising",
    description: "Create eye-catching ad imagery.",
  },
];

export default function FourOhFourPage({ }) {
  return <DefaultLayout
    title={"404 | Lazy Prompts"}
    description="This page does not exist."
    mainClassName={css.main}
    light
  >
    <section className={`light ${css.error}`}>
      <div className="sectionInner">
        <h2>404 | Page Not Found</h2>
        <div className={css.subheadline}>Why not check out our top prompt categories instead?</div>

        <div className={css.grid}>
          {categories.map((category) => <div key={category.label} className={css.category}>
            <div className={css.icon}>{category.icon}</div>
            <div className={css.label}>{category.label}</div>
            <div className={css.description}>{category.description}</div>
          </div>)}
        </div>
      </div>
    </section>
  </DefaultLayout>;
}
